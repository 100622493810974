import "./article-swiper.scss";

import $ from "jquery";
import modules from "ym";
import "../../modules/swiper";
import listenToMediaQuery from "../../lib/listen-to-media-query";

let mobileMediaQuery = "(min-width: 576px)";
let hoveredElement = null;
let isMobile = true;

listenToMediaQuery(mobileMediaQuery, (isDesktop) => {
  isMobile = !isDesktop;
});

const renderSlide = (item) => {
  const { image_href, image_url } = item.info;
  const { message, title, text } = item.info;
  const linkText = message || title || text;

  return `
    <div class="swiper-slide" data-id="${item.id}">
        <div class="sm:hidden">
            ${item.full}
        </div>
        <div class="sm:visible">
            <div class="article-swiper__item-head">
              <a target="_blank" href="${image_href}">
                  <img src="${image_url}" alt="" width="80" height="80" loading="lazy" />
              </a>
            </div>
            <div class="article-swiper__item-link">
                <span title="${linkText}">${linkText}</span>
            </div>
        </div>
    </div>
    `.trim();
};

const renderPopupContent = (item) => {
  const { event } = item.info;
  const { image_href, image_url } = item.info;
  const { message, title, text } = item.info;
  const linkText = message || title || text;

  return `
    <div class="article-swiper-popup__event">
        <div>${event}</div>
    </div>
    <div class="article-swiper-popup__head">
      <a target="_blank" href="${image_href}">
          <img src="${image_url}" alt="" width="80" height="80" loading="lazy" />
      </a>
    </div>
    <div class="article-swiper-popup__link">
        <a target="_blank" href="${image_href}">${linkText}</a>
    </div>
    <div class="article-swiper-popup__user">
      <a target="_blank" href="${item.info.user_href}">${item.info.user_name}</a>
      <br />
      ${item.info.user_address}
    </div>
  `.trim();
};

const _appendItemFromQueue = (sliderData, swiper) => {
  const item = sliderData.appendQueue.shift();

  if (!item) {
    return false;
  }

  sliderData.container.classList.remove("article-swiper--empty");
  swiper.appendSlide(renderSlide(item));

  return true;
};

const appendFromQueue = (sliderData) => {
  getOrCreateSwiper(sliderData).then((swiper) => {
    const pages = Math.ceil(sliderData.items.length / sliderData.perPage) || 1;
    while (_appendItemFromQueue(sliderData, swiper) && pages === 1) {}
  });
};

const loadMoreSlides = (sliderData) => {
  const container = $(sliderData.container);
  const perPage = sliderData.perPage;
  const pages = Math.ceil(sliderData.items.length / perPage);
  const url = container.attr("data-url");

  container.toggleClass("article-swiper--loading", true);

  return new Promise((resolve, reject) => {
    if (sliderData.hasMore && !sliderData.loading) {
      sliderData.loading = true;

      const xhr = $.ajax({
        url: url.toString(),
        data: { "page": pages + 1, "per-page": perPage },
      });

      xhr.always(() => {
        sliderData.loading = false;
        container.toggleClass("article-swiper--loading", false);
      });

      xhr.fail(() => {
        resolve((sliderData.hasMore = false));
      });

      xhr.done((data) => {
        const itemsById = sliderData.itemsById;
        const items = sliderData.items;
        const queue = sliderData.appendQueue;
        const loaded = (data.items || []).filter((item) => !itemsById[item.id]);

        if ((sliderData.hasMore = !!loaded.length)) {
          // добавлять слайды на страницу будет по одному, а не все сразу
          // чтоб не было визуальных подтормаживаний

          loaded.forEach((item) => {
            itemsById[item.id] = item;
            items.push(item);
            queue.push(item);
          });

          appendFromQueue(sliderData);
        }

        resolve(sliderData.hasMore);
      });
    } else {
      resolve(sliderData.hasMore);
    }
  });
};

const getOrCreateSwiper = (sliderData) => {
  if (sliderData.swiper) {
    return Promise.resolve(sliderData.swiper);
  }

  const element = sliderData.container.querySelector(".swiper-container");
  const swiperOptions = {
    allowTouchMove: false,
    slidesPerView: "auto",
    roundLengths: true,
    autoHeight: true,
    spaceBetween: 20,
    loop: false,
    speed: 300,
    breakpoints: { 576: { allowTouchMove: true } },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };

  return new Promise((resolve) => {
    modules.require(["swiper"], (Swiper) => {
      sliderData.swiper = new Swiper(element, swiperOptions);
      sliderData.swiper.on("transitionStart", function () {
        sliderData.popup.attr("hidden", "hidden");
        hoveredElement = null;
      });
      sliderData.swiper.on("slideChange", function () {
        const perPage = sliderData.perPage;
        const page = Math.ceil((this.realIndex + 1) / perPage) || 1;
        const pages = Math.floor(this.slides.length / perPage) || 1;

        if (page === pages) {
          // находимся на последней странице, значит надо подгрузить еще слайдов
          loadMoreSlides(sliderData);
        }

        // как только произошла смена слайда,
        // есть смысла сразу добавить в документ еще слайд из очереди
        appendFromQueue(sliderData);
      });

      resolve(sliderData.swiper);
    });
  });
};

$(".article-swiper").each((i, element) => {
  // предварительная загрузка зависимостей
  modules.require(["swiper"], () => {});

  const sliderData = {
    popup: $('<div class="article-swiper-popup" />'),
    container: element,
    appendQueue: [],
    loading: false,
    hasMore: true,
    swiper: null,
    perPage: 15,
    itemsById: {},
    items: [],
  };

  const container = $(sliderData.container);
  const popup = $(sliderData.popup);

  container.on("mouseleave", ".article-swiper-popup", () => {
    popup.attr("hidden", "hidden");
    hoveredElement = null;
  });

  container.on(
    "mouseenter mousemove",
    ".article-swiper__item-head img",
    (e) => {
      const image = $(e.target);
      const target = image.closest(".swiper-slide");

      getOrCreateSwiper(sliderData).then((swiper) => {
        if (isMobile || swiper.animating || hoveredElement === target.get(0)) {
          return;
        }

        hoveredElement = target.get(0);

        const itemId = target.attr("data-id");

        popup.html(renderPopupContent(sliderData.itemsById[itemId]));
        popup.removeAttr("hidden");
        popup.appendTo(container);
        popup.position({
          collision: "none",
          my: "left top",
          at: "left-50 top-35",
          of: image,
        });
      });
    }
  );

  loadMoreSlides(sliderData);
});
