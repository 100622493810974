import "./_vcard-tiles.scss";

import $ from "jquery";

$(function () {
  const images = $(".vcard-tile__image");

  images.each(function (index, image) {
    const $image = $(image);
    const $link = $image.parent(".vcard-tile__link");

    const src =
      $image.data("image") === "null.png"
        ? $link.data("placeholder")
        : [
            $image.data("cdn"),
            $image.data("record-id"),
            Math.round($link.width()) + "x" + Math.round($link.height()),
            $image.data("action"),
            $image.data("folder"),
            $image.data("image"),
          ]
            .join("/")
            .replace(/\/+/g, "/");

    $image.on("load", () => $image.css("opacity", "1"));
    $image.attr("src", src);
  });
});
