import $ from "jquery";
import "./components/vcard-show-background.js";
import "./components/vcard-like.js";
import "./components/vcard-tiles/vcard-tiles.js";
import "./components/article-swiper/article-swiper";

// задача https://estua.megaplan.ru/task/1011010/card/
// доработки п.4
$(function () {
  var wrap = $(".vcard-blog-categories");

  if (wrap.length) {
    var wrapHeight = $(wrap).height();
    $(wrap).css("max-height", wrapHeight);

    wrap.find('li[data-key="toggler"] a').on("click", function (e) {
      e.preventDefault();
      wrap.toggleClass("opened");
    });

    wrap.find("li").each(function (index, el) {
      if ($(el).hasClass("selected") && index > 4) {
        wrap.addClass("opened");
      }
    });
  }

  var test_options = {
    width: 400 + 30 * 2,
    draggable: true,
    autoOpen: true,
  };

  var contacts_buttons = [
    {
      class: "ui-button ui-button--big command-close-dialog",
      text: "Отлично",
    },
  ];

  $(document).on("click", ".open-dialog-contacts", function () {
    $(".contacts-dialog").estdialog(
      $.extend({}, test_options, {
        title: "Контакты",
        buttons: contacts_buttons,
      })
    );
  });

  $(document).on("click", ".est-steps__goto[data-step=2]", function () {
    $(".est-steps").attr("data-step", 2);
  });
  $(document).on("click", ".vcard-menu-close", function () {
    $(".vcard-menu-toggler-radio").prop("checked", false);
  });
});
