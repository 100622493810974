import $ from "jquery";

$(function () {
  const class_name = "vcard-cabinet-like--liked";

  if ($(".vcard-cabinet-likes").length > 0) {
    $.post($(".vcard-cabinet-likes").data("url"), function (html) {
      $(".vcard-cabinet-likes").html(html);
    });
  }

  $(document).on(
    "click",
    '[data-trigger="vcard-like"]:not([disabled])',
    function (e) {
      let _trigger = $(this);
      _trigger.attr("disabled", true);

      $.post(_trigger.data("url") + "?m=" + Math.random(), function (response) {
        _trigger.toggleClass(class_name);

        let _counter = _trigger.find(".vcard-cabinet-like__counter");
        if (response.count > 0) {
          _counter.removeClass("hidden");
          _counter.html(response.count);
        } else {
          _counter.addClass("hidden");
        }
      }).done(function () {
        _trigger.attr("disabled", false);
      });
    }
  );
});
