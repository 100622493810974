import $ from "jquery";

$(function () {
  const class_name = "vcard-show-background";
  const body = $("body");

  $(document).on("click", '[data-trigger="background-viewer"]', function (e) {
    body.toggleClass(class_name);
  });
});
